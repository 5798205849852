<template>
  <div>
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-2 variant="info"
            >Publication Ethics and Publication Malpractice Statement Policy
            <b-icon-arrow-down></b-icon-arrow-down
          ></b-button>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <p>
                (The following comments are founded on Elsevier guidelines and
                COPE's Best Practice Guidelines for Journal Editors.)
              </p>
              <p>
                We at IJIRE (International Journal of Innovative Research
                Engineering) strongly believe in following these guidelines to
                maintain fair practice. We are committed to fair practice of
                publication. Success in this regard can be achieved if the
                following participants will practice ethics:
              </p>
              <div>
                <h5>Ethical guidelines for journal publication:</h5>
                <p>
                  The International Journal of Innovative Research in
                  Engineering is committed to ensuring ethics in the publication
                  and quality of articles. Especially International Journal of
                  Innovative Research in Engineering follows the Code of Conduct
                  defined by the Committee of Publication Ethics (COPE).
                  <i>https://publicationethics.org/files/u2/New_Code.pdf</i>
                </p>
                <p>
                  These ethics include the editor adhering to specific rules for
                  dealing with users, writers, and critics and protocols for
                  dealing with grievances. All parties engaged, including
                  authors, editors, reviewers, and the international journal
                  publisher, must adhere to ethical standards. More
                  specifically,
                </p>
              </div>
              <div>
                <h5>Authors:</h5>
                <p>
                  The authors should present an objective discussion of the
                  significance of the International Journal of Innovative
                  Research work and sufficient details and references.
                </p>
                <p>
                  Authors should maintain accurate records of data associated
                  with their submitted manuscript and supply or provide access
                  to these data on reasonable request. The authors guarantee
                  that all data used in the article is genuine and authentic.
                  Where appropriate and allowed by employers, funding bodies,
                  and others who might have an interest, authors should deposit
                  data in a suitable repository or storage location for sharing
                  and further use by others.
                </p>
                <p>
                  Fraudulent or knowingly inaccurate statements constitute
                  unethical behavior and are unacceptable.
                </p>
                <p>
                  The authors should make sure that their writing is completely
                  original and that, if any other people's ideas or phrases were
                  used, they have been properly recognized. All forms of
                  plagiarism are undesirable and comprise unethical writing
                  behavior. Publishing malpractice is when a single submission
                  is sent to several publications at once. Articles describing
                  basically the same study should only be submitted to one
                  journal.
                </p>
                <p>
                  Authors should acknowledge the financial support and
                  help/guidance of others if appropriate.
                </p>
                <p>Authors should provide the disclaimer if appropriate.</p>
                <p>
                  Each co-author should have made a substantial contribution to
                  the study. All co-authors should approve the final version of
                  the manuscript before being submitted for publishing, and the
                  corresponding author should ensure this.
                </p>
                <p>
                  Authors are required to take part in the peer evaluation
                  procedure.
                </p>
                <p>
                  Authors should notify the editor promptly if a significant
                  error in their International journal research publication is
                  identified and cooperate with the editor to publish an
                  erratum, addendum, or corrigendum notice or to retract the
                  paper where it is deemed necessary.
                </p>
              </div>
              <hr />
              <div>
                <h5>Editors:</h5>
                <p>
                  Editors have complete responsibility and authority to
                  accept/reject a manuscript.
                </p>
                <p>
                  Editors should evaluate manuscripts exclusively based on their
                  academic merit. Editors act in a balanced, objective, and fair
                  way while carrying out their expected duties, without
                  discrimination on the grounds of gender, sexual orientation,
                  religious or political beliefs, or ethnic or geographical
                  origin of the authors. .
                </p>
                <p>
                  Editors accept the international journal paper when reasonably
                  sure.
                </p>
                <p>
                  Editors must not use unpublished information in the editor's
                  research without the author's express written consent.
                </p>
                <p>Editors preserve the anonymity of reviewers.</p>
                <p>
                  When errors are found, editors promote the International
                  Journal of Innovative Research Publication of correction or
                  retraction.
                </p>
                <p>
                  Editors should take reasonable responsive measures when
                  ethical complaints have been presented concerning a submitted
                  manuscript or published article.
                </p>
              </div>
              <hr />
              <div>
                <h5>Reviewers :</h5>
                <p>
                  Any manuscripts received for review must be treated as
                  confidential documents. Privileged information or ideas
                  obtained through peer review must be kept confidential and not
                  used for personal advantage.
                </p>
                <p>
                  Reports should be carried out honestly, and findings should be
                  clearly stated with supporting reasons so that writers can use
                  them to better the work. Any pertinent written work that needs
                  to be cited should be mentioned.
                </p>
                <p>
                  Referees should inform the editor and withdraw from the review
                  process if they feel unqualified to evaluate the international
                  journal research described in a manuscript or are aware that
                  doing so quickly won't be feasible.
                </p>
                <p>
                  Reviewers should not consider submissions that contain
                  conflicts of interest due to competing, joint, or other
                  relationships or links with any of the papers' writers,
                  businesses, or institutions.
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    <p>Publisher monitors and safeguards publishing ethics</p>
                  </li>
                  <li><p>Publisher communicates journal policies.</p></li>
                  <li>
                    <p>
                      The publisher respects the privacy of all parties
                      involved.
                    </p>
                  </li>
                  <li><p>Publisher fosters editorial independence.</p></li>
                  <li>
                    <p>
                      The publisher maintains the integrity of the academic
                      record.
                    </p>
                  </li>
                  <li>
                    <p>
                      Publisher protects intellectual property and copyrights.
                    </p>
                  </li>
                  <li>
                    <p>
                      The publisher publishes corrections, clarifications, and
                      retractions.
                    </p>
                  </li>
                  <li>
                    <p>
                      International Journal Publisher  constantly improves the quality of the journal.
                    </p>
                  </li>
                </ul>
              </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.btn-info {
  background-color: #72a9db;
}
.card {
  border: none;
}
</style>