<template>
  <div>
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-4 variant="info"
            >Double Blind Peer Review Policy
            <b-icon-arrow-down></b-icon-arrow-down>
          </b-button>
        </b-card-header>
        <b-collapse
          id="accordion-4"
          visible
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-card-text>
              <div>
                <p>
                  International Journal of Innovative Research in Engineering
                  (IJIRE) (Online), a Bi-monthly international journal for
                  publishing new ideas founded by academicians, educationists,
                  engineers, and corporate people. The research results and
                  fundamental advancement are all aspects of Innovative Research
                  in Advanced Engineering & Technology, various engineering
                  disciplines, etc. IJIRE is a scholarly open, accessible,
                  Double Blind Peer Review Journal that helps an academic person
                  and the student community. IJIRE provides the academic
                  community and industry for submitting original research and
                  applications related to Various Engineering disciplines and
                  Advanced Technologies.
                </p>
              </div>
              <div>
                <p>
                  The function of reviewers in the scholarly international
                  journal research publication is crucial. IJIRE employs a
                  double-blind peer review procedure, which means that
                  throughout the review process, both the names of the
                  reviewer(s) and author(s) are kept secret from one another. As
                  a result, neither the author(s) nor the reviewer(s) of the
                  document will be able to determine the name of the other (s).
                  Peer review enhances networking opportunities within
                  International Journal of Innovative Research groups and
                  assists in validating research by establishing a framework for
                  its evaluation. Despite critiques, peer review is still the
                  only commonly used strategy for study validation.
                </p>
              </div>
              <div>
                <p>
                  All submitted papers will be reviewed by a double-blind peer
                  review process which may take a minimum of 01 to 03 weeks from
                  the submission date. We advise all the author(s) not to submit
                  the same paper to multiple journals. It would help if you
                  waited for the review status of the paper.
                </p>
              </div>
              <div>
                <p>
                  <b
                    >IJIRE is committed to expediting the assessment and
                    publication of fully approved papers. To ensure a
                    high-quality publishing, all entries are subjected to a
                    stringent review procedure. The following are the
                    characteristics of the peer evaluation process:
                  </b>
                </p>
                <p>
                  Simultaneous submissions of the same manuscript to different
                  journals will not be tolerated.
                </p>
                <p>
                  Manuscripts with contents outside the scope will not be
                  considered for review.
                </p>
                <p>
                  Papers will be refereed by at least 3 or 4 experts (reviewers)
                  as suggested by the editorial board, of which 01 from India
                  and the rest 02 or 03 from overseas.
                </p>
                <p>
                  In addition, Editors will have the option of seeking
                  additional reviews when needed.
                </p>
                <p>
                  Authors will be informed when Editors decide further review is
                  required. The journal's Editors-in-Chief make all publication
                  decisions based on the referees' reports (reviewers' reports).
                </p>
                <p>
                  Authors of papers that are not accepted are notified promptly.
                </p>
                <p>
                  All manuscripts received are handled as confidential records.
                  All articles submitted will be reviewed by a double-blind
                  review procedure.
                </p>
                <p>
                  All manuscripts submitted for publication in IJIRE
                  (International Journal of Innovative Research Engineering)
                  were cross-checked for plagiarism software. Manuscripts found
                  to be plagiarized during the initial stages of review are
                  outrightly rejected and not considered for publication in the
                  journal.
                </p>
                <p>
                  The Editor-in-Chief will launch a preliminary inquiry if a
                  work is discovered to have been plagiarized after it has been
                  published, perhaps with the aid of a pertinent group that has
                  been assembled. The publication will contact the author's
                  Institute/College/University and Funding Agency if any if the
                  paper is discovered to have excessive levels of plagiarism.
                </p>
              </div>
              <h6>
                IJIRE strictly uses following double blind peer review process
              </h6>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.btn-info {
  background-color: #72a9db;
}
.card {
  border: none;
}
</style>